<template>

    <div class="itin-contact">
      <!--div class="itin-subheader">
        <h4><font-awesome-icon icon="credit-card"/> Pagos realizados: <span>ARS {{toCurrencyFormat( booking.TotalCost - booking.BalanceDue)}}</span></h4>
      </div-->
      <div >
        <div class="itin-payment-content group" v-for="payment in payments">
          <div class="content-small">
            {{payment.name}} {{payment.date.format('DD/MM')}}
          </div>
          <div class="content-large">
            {{payment.cardNumber}}
          </div>
          <div class="content-medium">
            {{payment.amount}}
          </div>
          <div class="content-large d-inline-flex">
            {{payment.status}}
<!--            <a :href="payment.url" v-if="payment.url" target="_blank">Ver cupón</a>-->
            <p class="btn-link pl-1 mb-0" @click="sendEmail(payment.MPPaymentID)" v-if="payment.url">Reenviar Cupón de Pago</p>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  import {formatMoney} from "../../utils/utils";
  import {resendEmailPayment} from "../../api/api";
  export default {
    name: 'payment-summary',
    computed: {
      ...mapState(['booking']),
      payments: function() {
        if (this.booking) {
          var result = [];
          var that = this;
          this.booking.Payments.forEach(function(element) {
            result.push({
              "name": element.PaymentMethod.Name,
              "amount": element.CurrencyCode + " " + formatMoney(element.CollectedPaymentAmount),
              "cardNumber": that.cardNumber(element),
              "date": moment(element.ApprovalUTC),
              "status": that.getStatus(element),
              "url": that.getMPUrl(element),
              "MPPaymentID": element.MPPaymentID
            })
          });
          return result;
        }
        return []
      }
    },
    methods:  {
      getStatus: function(payment) {
        if (payment.AuthorizationStatus == 4) {
          return 'Aprobado';
        }

        if (!payment.MercadoPagoPayment || payment.MercadoPagoPayment.Status == 'FAILED') {
          return 'Erroneo - No procesado';
        }

        if (payment.MercadoPagoPayment.Status == 'approved') {
          return 'Pendiente - Llamar al call center';
        }

        if (payment.MercadoPagoPayment.Status == 'pending' && payment.MercadoPagoPayment.type == 'ticket') {
          return 'Pendiente de pago';
        }

        return "Rechazado(" + payment.AuthorizationStatusDetailMessage + ")"
      },
      toCurrencyFormat: function (amount) {
        return formatMoney(amount);
      },
      cardNumber: function(payment) {
        if (payment.MercadoPagoPayment && payment.MercadoPagoPayment.type == 'ticket') {
          return ""
        }
        return payment.AccountNumber;
      },
      getMPUrl: function(payment) {
        if (payment.MercadoPagoPayment && payment.MercadoPagoPayment.type == 'ticket' && payment.AuthorizationStatus == 2) {
          return payment.MercadoPagoPayment.external_resource_url;
        }
        return null;
      },
      openCupon: function(url) {
        window.open(url, '_blank');
      },
      sendEmail: function(MPPaymentID) {
        this.$store.commit('loading', true);
        resendEmailPayment(this.booking.BookingID, this.booking.RecordLocator, MPPaymentID, (result) => {
          this.$store.commit('loading', false);
        })
      }
    }
  }
</script>

