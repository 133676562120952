<template>
  <div>
    <andes-header class="no-printable"></andes-header>
    <div class="container">
      <div class="row">
        <div class="col-lg-9">
          <div class="row no-printable">
            <div class="col">
              <div class="current-srch-container">
                <div class="current-srch-data">
                  <div class="current-srch-ond full-width-title">
                    <h1>Tarjeta de Embarque</h1>
                    <p>Usted ha finalizado correctamente el proceso de Check-In. Por favor imprima sus tarjetas de
                      embarque.</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <hr class="no-printable"/>

          <div class="row mb-5">
            <div class="col-md-7 col-sm-12 tarjeta myDivToPrint">

              <!-- TARJETA DE EMBARQUE 1 -->
              <div class="checkin-print-pass board_1 mb-3" v-for="(p, index) in paxs" v-if="p.paxLeg.LiftStatus == 1">
                <div class="row">
                  <div class="col-sm-12 boarding-left">

                    <div class="row">
                        <img class="logo-card col-6" src="@/img/andes_logo.jpg" alt="">
                        <img class="logo-card col-5" :src="customerLogo" alt="">
                    </div>
                    <div class="checkin-print-pass-date">
                      <h4>{{getFormatDate}}</h4>
                      <span class="checkin-print-boarding-document">Tarjeta de Embarque</span>

                    </div>
                    <div class="card-left">
                      <h4 class="checkin-print-pass-name-andes">{{p.pax.FirstName}} {{p.pax.MiddleName}}
                        {{p.pax.LastName}}</h4>
                      <p class="checkin-print-pass-name-andes" v-if="p.pax.Infant">Viajando con
                        {{p.pax.Infants[0].FirstName}} {{p.pax.Infants[0].MiddleName}} {{p.pax.Infants[0].LastName}}</p>
                      <p class="checkin-print-pass-name-andes" v-if="p.isInfant">Infante</p>
                      <h4 class="checkin-print-carrier-code">DNI: {{p.pax.DocNumber}}</h4>

                      <div class="checkin-flight-details">
                        <h1>{{p.segment.DepartureStation}}</h1>
                        <h1 class="checkin-print-flight-image">
                          <img src="@/img/plane-32.png" alt="">
                        </h1>
                        <h1>{{p.segment.ArrivalStation}}</h1>
                      </div>

                      <div class="checkin-print-boarding-details">

                        <div class="first-details-container">
                          <h5>{{p.segment.DepartureStation}}
                            {{getAirportName(p.segment.DepartureStation)}}
                            ({{p.segment.DepartureStation}})</h5>
                          <h4 class="checkin-print-flight-arrival-station">{{p.segment.ArrivalStation}}
                            {{getAirportName(p.segment.ArrivalStation)}}
                            ({{p.segment.ArrivalStation}})</h4>

                        </div>
                        <h5 class="checkin-print-flight-number">
                            <strong class="mr-2">{{booking.RecordLocator}}</strong> Vuelo OY {{p.paxLeg.InventoryLeg.FlightNumber}}
                        </h5>

                        <div class="second-details-container">
                          <ul class="checkin-print-airport-details">
                            <li>
                              <h5>Embarque</h5>
                              {{boardingTime}}hs
                            </li>
                            <li>
                              <h5>Zona</h5>
                              99
                            </li>
                            <li v-if="!hideBoardingSeat">
                              <h5>Asiento</h5>
                              {{p.paxLeg.UnitDesignator}} {{getClass(p.segment)}}
                            </li>
                            <li v-if="hideBoardingSeat">
                              <span><b>A ASIGNAR EN EL AEROPUERTO POR ORDEN DE LLEGADA</b></span>
                            </li>
                          </ul>
                          <p>
                            <strong>Salida:</strong> {{departureTime}}hs -
                            <strong>Llegada:</strong> {{arrivalTime}}hs -
                          </p>
                          <p class="m-0">
                            <!--strong>Extras: </strong-->
                            <span>{{getSSRs(p)}}</span>
                          </p>
                          <p><h5>Número de Orden: {{p.segment.OrderNumber}}</h5></p>
                        </div>
                      </div>

                      <!--div class="checkin-print-gate-warning">
                        <p>*Las puertas de embarque podrían cambiar. Verifique en los monitores del aeropuerto. Recuerde que el horario del cierre de puertas es 20 minutos antes de la salida del vuelo</p>
                      </div-->
                    </div>

                    <div class="card-right h-100">
                      <div class="checkin-print-pass-code-container h-100">
                        <img class="mt-3" :id="'barcodeimg'+index"/>
                      </div>
                      <!--<p class="checkin-print-boarding-document">seq. {{p.paxLeg.BoardingSequence}}</p>-->
                    </div>

                  </div>

                  <!--div class="col-sm-5 boarding-right">
                    <img class="logo-card" src="@/img/andes_logo.jpg" alt="">
                    <div class="checkin-print-pass-date">
                      <h4>{{getFormatDate}}</h4>
                      <span class="checkin-print-boarding-document">Recibo de Embarque</span>
                    </div>
                    <div>
                      <h4 class="checkin-print-pass-name-andes">{{p.pax.FirstName}} {{p.pax.MiddleName}}
                        {{p.pax.LastName}}</h4>
                      <h4 class="checkin-print-carrier-code">{{booking.RecordLocator}}</h4>

                      <div class="checkin-flight-details">
                        <h1>{{p.segment.DepartureStation}}</h1>
                        <h1 class="checkin-print-flight-image">
                          <img src="@/img/plane-32.png" alt="">
                        </h1>
                        <h1>{{p.segment.ArrivalStation}}</h1>
                      </div>

                      <div class="checkin-print-boarding-details">

                        <div class="first-details-container">
                          <h5>{{p.segment.DepartureStation}}
                            {{getAirportName(p.segment.DepartureStation)}}
                            ({{p.segment.DepartureStation}})</h5>
                          <h4 class="checkin-print-flight-arrival-station">{{p.segment.ArrivalStation}}
                            {{getAirportName(p.segment.ArrivalStation)}}
                            ({{p.segment.ArrivalStation}})</h4>
                          <h5 class="checkin-print-flight-number">Vuelo OY {{p.paxLeg.InventoryLeg.FlightNumber}}</h5>
                        </div>

                        <div class="second-details-container">
                          <ul class="checkin-print-airport-details">
                            <li>
                              <h5>Embarque</h5>
                              {{boardingTime}}hs
                            </li>
                            <li>
                              <h5>Zona</h5>
                              99
                            </li>
                            <li>
                              <h5>Asiento</h5>
                              {{p.paxLeg.UnitDesignator}}
                            </li>
                          </ul>
                          <p>
                            <strong>Salida:</strong> {{departureTime}}hs -
                            <strong>Llegada:</strong> {{arrivalTime}}hs -
                          </p>
                          <p class="m-0">
                            <span>{{getSSRs(p)}}</span>
                          </p>
                          <p><h5>Número de Orden: {{p.segment.OrderNumber}}</h5></p>
                        </div>
                      </div>
                    </div>
                  </div-->
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-3 pb-3">
          <div class="card-buttons text-center">
            <button type="button" class="btn mr-1" @click="print">Descargar</button>
            <button type="button" class="btn" @click="back">Itinerario</button>
          </div>
        </div>
      </div>
    </div>
    <loading-modal v-show="loading"></loading-modal>
    <andes-footer class="no-printable"></andes-footer>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import PDF417 from 'pdf417-generator'
  import {createCanvas} from 'canvas'
  import AndesHeader from "../components/AndesHeader";
  import LoadingModal from "../components/LoadingModal";
  import AndesFooter from "../components/AndesFooter";
  import moment from 'moment';

  export default {
    components: {
      LoadingModal,
      AndesHeader,
      AndesFooter,
    },
    name: 'boarding-pass',
    data: function () {
      return {
        ssrs: null,
        fees: null
      }
    },
    computed: {
      ...mapState(['booking', 'loading', 'boardingFlight', 'airports', 'passengerID']),
      getFormatDate: function () {
        return moment(this.boardingFlight.DepartureDate).locale('es').format('ddd DD MMMM YYYY')
      },
      paxs: function () {
        var list = [];
        for (var pax of this.booking.Passengers) {
          if (this.passengerID && pax.PassengerID != this.passengerID) continue;
          for (var seg of pax.Segments) {
            if (seg.SegmentFlightKey == this.boardingFlight.SegmentFlightKey) {
              list.push({'pax': pax, 'paxLeg': seg.Legs[0], 'segment': seg});
              if (_.has(pax, 'Infant') && pax.Infant) {
                list.push({'pax': pax.Infants[0], 'paxLeg': seg.Legs[0], 'segment': seg, "isInfant": true});
              }
            }
          }
        }
        // this.paxs = list;
        return list
      },
      customerLogo () {
        for (let pax of this.paxs) {
            for (let leg of pax.segment.Legs) {
              if (leg.InventoryLeg && leg.InventoryLeg.Customer && leg.InventoryLeg.Customer.Logo) {
                let img = require(`@/img/customer/${leg.InventoryLeg.Customer.Logo}`);
                return img;
              }
            }
        }

      },
      boardingTime: function () {
        return moment(this.boardingFlight.Legs[0].InventoryLeg.STD).add(-45, 'm').locale('es').format("HH:mm")
      },
      departureTime: function () {
        return moment(this.boardingFlight.Legs[0].InventoryLeg.STD).locale('es').format("HH:mm")
      },
      arrivalTime: function () {
        return moment(this.boardingFlight.Legs[this.boardingFlight.Legs.length-1].InventoryLeg.STA).locale('es').format("HH:mm")
      },
      hideBoardingSeat: function () {
        return this.boardingFlight.Legs[0].InventoryLeg.HideBoardingSeat
      }
    },
    methods: {
      generateBarcode: function (index) {
        var idImg = "#barcodeimg" + index;
        var code = 'M1';
        let paxName = this.paxs[index].pax.LastName + '/' + this.paxs[index].pax.FirstName
            + (this.paxs[index].pax.MiddleName? this.paxs[index].pax.MiddleName: '');
        paxName = paxName.substring(0, 20);
        paxName = paxName.padStart(20, " ");
        code += paxName;
        code += ' ';
        code += this.booking.RecordLocator.padStart(7, " ");
        code += this.paxs[index].segment.DepartureStation;
        code += this.paxs[index].segment.ArrivalStation;
        code += 'OY';
        code += ' ';
        code += this.paxs[index].paxLeg.InventoryLeg.FlightNumber.padStart(4, "0");
        code += ' ';
        code += moment(this.paxs[index].paxLeg.InventoryLeg.DepartureDate).format('DDD').padStart(3, "0");
        code += ' ';
        code += this.paxs[index].paxLeg.UnitDesignator.padStart(4, "0");
        code += this.paxs[index].paxLeg.BoardingSequence.toString().padStart(4, "0");
        code = code.padEnd(158, " ");
        code += this.paxs[index].segment.SegmentID;

        let canvas = createCanvas(200, 50);
        PDF417.draw(code, canvas)
        $(idImg)[0].src = canvas.toDataURL();

        var parentH = $('.checkin-print-pass-code-container').height();
        $(idImg)[0].style.height = parentH * 0.15 + 'px';
        $(idImg)[0].style.width = parentH * 0.6 + 'px';
        $(idImg)[0].style.right = parentH * 0.1 + 'px';
        $(idImg)[0].style.top = parentH * 0.7 + 'px';
      },
      getAirportName: function (code) {
        var r = _.find(this.airports, (a) => a.id == code);
        return r.name;
      },
      getClass: function (segment) {
        return "Class " + segment.ClassOfService + " (" + segment.ProductClass.Name + ")"
      },
      back: function () {
        this.$router.replace("itinerary");
      },
      print: function () {
        window.print();
      },
      getSSRs: function(p) {
            let res = [];
            for (let fee of p.segment.Fees) {
                if (res.indexOf(fee.FeeCode)==-1) {
                    res.push(fee.FeeCode);
                }
            }
            for (let ssr of p.paxLeg.SSRs) {
                if (res.indexOf(ssr.SSRCode)==-1) {
                    res.push(ssr.SSRCode);
                }
            }
            return res.join(', ');
      },
    },
    mounted: function() {
      if (_.isEmpty(this.booking)) {
        this.$router.replace('/');
      }
      for (var index of _.range(this.paxs.length)) {
        this.generateBarcode(index);
      }
      var that = this;
      $(window).resize(function () {
        for (var index of _.range(that.paxs.length)) {
          that.generateBarcode(index);
        }
      });
    }
  }
</script>

